import axios from "axios";
import NProgress from "nprogress";

const elasticAxios = axios.create({
  baseURL: process.env.VUE_APP_APPBASE_URL
});

elasticAxios.interceptors.request.use(
  config => {
    NProgress.start();
    config.headers["Accept"] = "application/json";
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

elasticAxios.interceptors.response.use(
  response => {
    NProgress.done();
    return response;
  },
  function(error) {
    NProgress.done();

    return Promise.reject(error);
  }
);

export default elasticAxios;

<template>
  <v-card>
    <v-card-title>
      <v-btn
        @click="$router.push({ name: 'KBDrugs' })"
        class="mx-2"
        fab
        dark
        small
        color="primary"
      >
        <v-icon dark>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="
          $router.push({
            name: 'EPGDrugDetail',
            params: { drugId }
          })
        "
        class="mx-2"
        fab
        dark
        small
        color="primary"
      >
        <v-icon dark>
          mdi-book
        </v-icon>
      </v-btn>
      <v-btn @click="updateKBDrug" class="mx-2" fab dark small color="green">
        <v-icon dark>
          mdi-content-save
        </v-icon>
      </v-btn>
      <v-btn class="mx-2" fab dark small color="red">
        <v-icon dark>
          mdi-delete
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12 px-5" sm="12" md="6">
              <v-card>
                <v-card-title>
                  Nombres principales
                </v-card-title>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="kbData.main_names.en"
                      label="Inglés"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="kbData.main_names.es"
                      label="Español"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="kbData.main_names.po"
                      label="Portugués"
                      required
                    ></v-text-field>
                  </v-col> </v-row
              ></v-card>
              <v-card>
                <v-text-field
                  v-model="kbData.slug"
                  :rules="validationRules.slugRules"
                  label="ID"
                  required
                ></v-text-field>

                <v-text-field v-model="kbData.cas" label="CAS"></v-text-field>

                <v-switch
                  v-model="kbData.hide_in_pgx"
                  label="Ocultar en PGX"
                ></v-switch>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <MultipleInput
                label="Nombres comerciales"
                v-model="kbData.brandnames"
                textField="brandname"
              />

              <MultipleInput
                label="ATCs"
                v-model="kbData.atcs"
                textField="atc"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Strapi from "@/services/Strapi.js";
import Elasticsearch from "@/services/Elasticsearch.js";
import MultipleInput from "@/components/mylogy/MultipleInput.vue";
export default {
  props: ["drugId"],
  components: { MultipleInput },
  data() {
    return {
      valid: false,
      validationRules: {
        slugRules: [v => !!v || "El campo ID es obligatorio"]
      },
      kbData: {
        atcs: [],
        brandnames: [],
        cas: null,
        dc_concept_relationships: [],
        epg_drug_categories: [],
        has_drug_central: null,
        has_epg: null,
        has_pharm_gkb: null,
        hide_in_pgx: false,
        id: null,
        induces: [],
        inhibits: [],
        kb_inductions: [],
        kb_inhibitios: [],
        kb_relationships: [],
        kb_subs: [],
        main_names: {
          en: null,
          es: null,
          po: null
        },
        pharm_gkb_chemical: null,
        pharmgkb: null,
        slug: null,
        substrate_of: []
      }
    };
  },
  computed: {},
  mounted() {
    let self = this;
    Strapi.get(`/kb-drugs/${this.drugId}`).then(res => {
      self.kbData = res.data;
    });
  },
  methods: {
    updateKBDrug() {
      Strapi.put(`/kb-drugs/${this.drugId}`, this.kbData).then(res => {
        self.kbData = res.data;
        Elasticsearch.post(`/kb-drugs/_doc/${self.kbData.id}`);
      });
    }
  }
};
</script>
<style scoped></style>
